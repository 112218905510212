const menuLinks = [
  {
    label: 'hi',
    url: '/hi/'
  },
  {
    label: 'blog',
    url: '/blog/'
  },
  {
    label: 'lab',
    url: '/lab/'
  }
]

export default menuLinks
